import * as React from 'react';
import Layout from '../components/Layout';
import { Box, Container, Stack, Typography } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Repairs(): JSX.Element {
  return (
    <Layout title="Jewellery Repairs | Oclee &amp; Son">
      <Stack minHeight="100vh">
        <Header page="repairs" />
        <Box flex={1} mb={4}>
          <Container>
            <Typography variant="h1" gutterBottom>Repairs</Typography>
            <Typography variant="body1" gutterBottom>
              When you need jewellery repairs in Folkestone, we would like to be your
              first choice. Whoever you are, and whatever you require, you can
              benefit from our vast wealth of knowledge and experience in all aspects
              of jewellery and watches.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We stock and repair items and jewellery such as:
              <ul>
                <li>Gold</li>
                <li>Silver</li>
                <li>Diamond</li>
                <li>Watches</li>
              </ul>
            </Typography>
            <Typography variant="body1">
              So, if your watch is broken and you couldn&apos;t bear to throw it away we
              have the perfect service for you. Any pieces of jewellery that have
              been damaged can be repaired by our expert craftsmen, and we gain much
              satisfaction from restoring a much beloved piece back to its original
              splendour!
            </Typography>
          </Container>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}
